import { Link } from 'gatsby';
import React from 'react';

const year = new Date().getFullYear();

// add date to bypass netlify build cache
const date = new Date().toISOString();

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <p data-date={date}>&copy; ErgZone {year}. All Rights Reserved.</p>
        <ul className="list-inline">
          <li className="list-inline-item">
            <Link to="/privacy">Privacy</Link>
          </li>
          <li className="list-inline-item">
            <Link to="/terms">Terms</Link>
          </li>
          <li className="list-inline-item">
            <Link to="https://help.erg.zone/">FAQ</Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}
